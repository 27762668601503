import React, { useEffect, useState } from 'react';
import Markdown from 'react-markdown';
import { graphql } from 'gatsby';
import { Layout, Section } from 'components';
import { GatsbyImage } from 'gatsby-plugin-image';
import axios from 'axios';

const Book = (req, res) => {
  const [book, setBook] = useState({
    title: '',
    description: '',
    image: '',
    genre: null,
  });

  useEffect(() => {
    let url = `${process.env.API_URL}/books?slug=${req.bookSlug}`;
    axios
      .get(url)
      .then(function (response) {
        setBook(response.data[0]);
        // var result = response.data;
        // console.log(result);
        // return result;
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);
  const seo = {
    metaTitle: book.title,
    metaDescription: book.description,
    // shareImage: book.image,
    book: true,
  };

  return (
    <Layout seo={seo}>
      <div className='uk-padding-large'></div>
      <Section title='도서 정보'>
        <div className='uk-grid'>
          <div className='uk-width-1-2@m'>
            <div className='uk-margin-large-bottom'>
              <div className='uk-flex uk-flex-center uk-flex-middle'>
                {/* <GatsbyImage
                  image={book.image.localFile.childImageSharp.gatsbyImageData}
                  className='uk-box-shadow-large'
                  alt=''
                /> */}
                <img
                  src={book.image.url}
                  className='uk-box-shadow-large'
                  alt=''
                />
              </div>
            </div>
          </div>
          <div className='uk-width-1-2@m'>
            <div className='uk-flex uk-flex-middle'>
              <div className='uk-flex uk-flex-column'>
                <div className='uk-h2 uk-text-bold'>{book.title}</div>
                <div className='uk-h4 uk-margin-small'>저자: {book.author}</div>
                <div className='uk-h4 uk-margin-small'>
                  분류: {book.genre?.name}
                </div>
                <div className='uk-h5'>
                  <Markdown source={book.content} escapeHtml={false} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>
    </Layout>
  );
};

export default Book;
